<template>
  <div class="conrtainer">
    <div class="assignmentReportHeader">
      <strong style="color: Black">Effort Analysis</strong>
      <strong><a style="color: #642c90"> / Assignment Report</a></strong>
    </div>
    <div class="divider linediv hide-on-small-only" style="width: 100%"></div>
    <div class="card-panel Tcard">
      <div class="row">
        <div class="col l3 m6 s12">
          <label for="course" class="dropdownLabel">Course</label>
          <select
            name="course"
            id="course"
            class="browser-default selectDropdown"
            v-model="selectCourse"
            @change="onCourseChange($event)"
          >
            <option value="0" disabled selected>--Select--</option>
            <option
              :value="course.courseId"
              v-for="course in courseData"
              :key="course.affiliationCourseId"
            >
              {{ course.courseName }}
            </option>
          </select>
          <div v-show="CourseValidation" style="color: red">
            {{ this.CourseValidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12">
          <label for="batch" class="dropdownLabel">Batch</label>
          <VueMultiselect
            class="browser-default selectDropdown"
            tag-placeholder="Add this Batch"
            v-model="selectBatch"
            placeholder="Search Or Add Batch"
            @update:model-value="updateSelectedBatch"
            label="BatchName"
            track-by="BatchId"
            :disabled="disabled"
            :options="courseBatchList"
            :multiple="true"
            :taggable="true"
            :limit="1"
            @tag="addBatch"
          >
            <template #beforeList>
              <div>
                <button @click="selectBatchNone">None</button>
                <button @click="selectBatchAll">All</button>
              </div>
            </template>
          </VueMultiselect>
          <!-- <select
            name="batch"
            id="batch"
            class="browser-default selectDropdown"
            v-model="selectBatch"
            @change="onBatchChange($event)"
          >
            <option value="" disabled selected>--Select--</option>
            <option
              :value="batch.BatchId"
              v-for="batch in courseBatchList"
              :key="batch.BatchId"
            >
              {{ batch.BatchName }}
            </option>
          </select> -->
          <div v-show="BatchValidation" style="color: red">
            {{ this.BatchValidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12">
          <label for="date" class="dropdownLabel">Date</label>
          <select
            name="date"
            id="date"
            class="browser-default selectDropdown"
            v-model="dateRangeType"
            @change="showCalendar()"
          >
            <option value="" disabled selected>--Select--</option>
            <option v-for="(period, index) in Timeinterval" :key="period" :value="index + 1">
              {{ period }}
            </option>
          </select>
        </div>
        <div class="col l3 m6 s12 btnHide">
          <a class="AffsubmitBtn btn" @click="getAssignmentReport()">Submit</a>
        </div>
      </div>
      <div class="row" v-if="isDateRangeOpen">
        <div class="col l3 m6 s12">
          <label for="startDate" class="dropdownLabel">Start Date</label>
          <input
            type="date"
            id="startDate"
            name="start date"
            max="2999-12-31"
            min="1900-01-01"
            class="browser-default datePicker"
            style="display: block"
            v-model="startDate"
            @change="showCalendar()"
          />
          <div v-show="isStartDateSelected" style="color: red">
            {{ this.DateValidationMessage }}
          </div>
        </div>
        <div class="col l3 m6 s12">
          <label for="endDate" class="dropdownLabel">End Date</label>
          <input
            type="date"
            id="enddate"
            name="end Date"
            max="2999-12-31"
            min="1900-01-01"
            class="browser-default datePicker"
            style="display: block"
            v-model="endDate"
            @change="showCalendar()"
          />
          <div v-show="isEndDateSelected" style="color: red">
            {{ this.DateValidationMessage }}
          </div>
        </div>
      </div>
      <div class="col l3 m6 s12 btnShow">
        <a class="AffsubmitBtn btn" @click="getAssignmentReport()">Submit</a>
      </div>
    </div>
    <div class="row tableBox" style="padding-bottom: 30px">
      <table class="striped" style="width: 100%">
        <thead>
          <tr>
            <th>Name</th>
            <!-- <th>Course</th>
          <th>Batch</th> -->
            <th>Total Assignment</th>
            <th>Pending</th>
            <th>Completed</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody class="card-panel">
          <tr v-for="(report, index) in AssignmentReports" :key="index">
            <td>{{ report.Name ? report.Name : "-" }}</td>
            <td>{{ report.Total ? report.Total : 0 }}</td>
            <td>{{ report.Pending ? report.Pending : 0 }}</td>
            <td>{{ report.Completed ? report.Completed : 0 }}</td>
            <td><a @click="get(report.Name, report.UserId)">View Assignment</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import moment from "moment";
import API from "../Api";
import MobileAPI from "../Mobileapi";

export default {
  data() {
    return {
      AssignmentReports: [],
      courseData: [],
      batchData: [],
      courseBatchList: [],
      // selectedBatches: [],
      courseId: null,
      batchId: [],
      selectCourse: -1,
      selectBatch: [],
      CourseValidation: false,
      BatchValidation: false,
      isDateRangeOpen: false,
      isStartDateSelected: false,
      isEndDateSelected: false,
      disabled: false,
      startDate: "",
      endDate: "",
      DateValidationMessage: "",
      CourseValidationMessage: "",
      BatchValidationMessage: "",
      dateRangeType: 1,
      Timeinterval: ["Today", "Yesterday", "Last Week", "Custom"],
    };
  },
  activated() {
    const user = this.$store.getters.user;
    if (this.$route.params.StudentAssignmentReportCourseId) {
      this.selectCourse = this.$route.params.StudentAssignmentReportCourseId;
      API.getBatches(user.AffiliationId, (response) => {
        this.batchData = response.data;
        this.courseBatchList = this.batchData.filter(
          (a) => a.CourseId.toString() === this.$route.params.StudentAssignmentReportCourseId,
          (this.CourseValidation = false),
        );
      });
    } else {
      this.selectCourse = "0";
    }
  },
  methods: {
    onCourseChange(event) {
      this.selectBatch = [];
      this.courseBatchList = this.batchData.filter(
        (a) => a.CourseId.toString() === event.target.value,
        (this.CourseValidation = false),
      );
      this.courseId = Number(event.target.value);
    },
    onBatchChange(event) {
      this.batchId = Number(event.target.value);
      this.BatchValidation = false;
    },
    showCalendar() {
      if (this.dateRangeType === 4) {
        this.isDateRangeOpen = true;
        this.isStartDateSelected = false;
        this.isEndDateSelected = false;
      } else {
        this.isDateRangeOpen = false;
      }
    },
    getAssignmentReport() {
      if (this.selectCourse === -1) {
        this.CourseValidation = true;
        this.CourseValidationMessage = "Please select course";
      } else {
        this.CourseValidation = false;
        this.CourseValidationMessage = "";
      }

      if (this.selectBatch.length === 0) {
        this.BatchValidation = true;
        this.BatchValidationMessage = "Please select Batch";
      } else {
        this.BatchValidation = false;
        this.BatchValidationMessage = "";
      }
      let beginDate = moment();
      let endingDate = moment();
      if (this.dateRangeType === -1) {
        return;
      }
      if (this.dateRangeType === 1) {
        beginDate = beginDate.format("YYYY-MM-DD");
        endingDate = endingDate.format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 2) {
        beginDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        endingDate = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 3) {
        beginDate = moment().subtract(1, "weeks").startOf("week");
        beginDate = beginDate.format("YYYY-MM-DD");
        endingDate = moment().format("YYYY-MM-DD");
        this.startDate = beginDate;
        this.endDate = endingDate;
      } else if (this.dateRangeType === 4) {
        beginDate = this.startDate;
        endingDate = this.endDate;
        if (beginDate === "") {
          this.DateValidationMessage = "Please Select start date";
          this.isStartDateSelected = true;
          return;
        }
        if (endingDate === "") {
          this.DateValidationMessage = "Please Select end date";
          this.isEndDateSelected = true;
          this.isStartDateSelected = false;
          return;
        }
        if (beginDate !== "" && endingDate !== "") {
          beginDate = moment(this.startDate, "YYYY-MM-DD");
          endingDate = moment(this.endDate, "YYYY-MM-DD");
          const differenceOfDays = endingDate.diff(beginDate, "days");
          if (differenceOfDays < 0) {
            window.M.toast({
              html: "End Date can not be less than Start Date",
            });
            return;
          }
          if (differenceOfDays > 30) {
            window.M.toast({
              html: "Select date range between 30 days",
            });
            return;
          }
        }
      }
      const data = {
        // CourseId: 1,
        // BatchId: 413,
        // FromDate: "2022-06-01",
        // ToDate: "2022-06-29",
        CourseId: this.courseId,
        BatchId: Array.from(this.batchId),
        FromDate: this.startDate,
        ToDate: this.endDate,
      };
      if (
        this.selectCourse !== -1 &&
        this.selectBatch !== [] &&
        this.startDate !== " " &&
        this.endDate !== " "
      ) {
        this.$store.dispatch("showLoader", true);
        MobileAPI.getAssignmentReport(data, (response) => {
          this.$store.dispatch("showLoader", false);
          this.AssignmentReports = response.data;
          if (response.responseCode === 201) {
            window.M.toast({
              html: "No records found for your current selection. Please try a different selection",
            });
            this.AssignmentReports = [];
          } else if (this.AssignmentReports.length === 0) {
            window.M.toast({
              html: "No records found for your current selection. Please try a different selection",
            });
          }
        });
      }
    },
    get(name, userId) {
      const user = this.$store.getters.user;
      const studentAssignmentReportParams = {
        CourseId: this.courseId,
        IsDateRangeOpen: this.isDateRangeOpen,
        UserName: name,
        UserId: userId,
        StartDate: this.startDate,
        EndDate: this.endDate,
        AffiliationId: Number(user.AffiliationId),
        DateRangeType: Number(this.dateRangeType),
        User: user,
      };
      localStorage.setItem("StudentAssignmentReportParams", studentAssignmentReportParams);
      this.$router.push({ name: "StudentAssignmentReport", params: studentAssignmentReportParams });
    },
    addBatch(newTag) {
      const tag = {
        BatchName: newTag,
        BatchId: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.courseBatchList.push(tag);
      this.selectBatch.push(tag);
    },
    updateSelectedBatch(value) {
      if (this.selectBatch.length >= 0) {
        this.selectedBatches = [];
      }
      this.selectedBatches = [];
      value.forEach((resource) => {
        this.selectedBatches.push(resource);
      });
      const batch = this.selectedBatches.map((a) => a.BatchId);
      this.batchId = batch;
      this.batchValidation = false;
    },
    selectBatchNone() {
      this.selectBatch = [];
    },
    selectBatchAll() {
      this.selectBatch = this.courseBatchList;
      this.selectedBatches = this.courseBatchList;
      const batch = this.selectedBatches.map((a) => a.BatchId);
      this.batchId = batch;
    },
  },

  created() {
    const user = this.$store.getters.user;
    API.getCourses({}, (response) => {
      this.courseData = response.data;
    });
    API.getBatches(user.AffiliationId, (response) => {
      this.batchData = response.data;
    });
  },
  components: { VueMultiselect },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:before,
[type="radio"].with-gap:checked + span:after {
  border: 2px solid #f59e00;
}

[type="radio"]:checked + span:after,
[type="radio"].with-gap:checked + span:after {
  background-color: #f59e00;
}
.assignmentReportHeader {
  margin-top: 19px;
  font-size: 25px;
  color: #642c90;
}

.linediv {
  margin-top: 18.5px;
  height: 0px;
  left: 132px;
  border: 1px solid #e0e0e0;
}
.Tcard {
  border-radius: 5px;
  margin-top: 24px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}
.dropdownLabel {
  font-size: 19px;
  color: #000;
  font-weight: 600;
}
.selectDropdown {
  margin-top: 8px;
  border-radius: 5px;
  height: 36px;
}
.datePicker {
  margin-top: 8px;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  border: 1.5px solid #e3e3e3;
}
.AffsubmitBtn {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 14px;
  margin-left: 41px;
  margin-top: 36px;
  width: 130px;
  height: 38px;
}
thead {
  background-color: #6a2f85;
}
th {
  color: #fff;
  border: none;
  border-radius: 0;
}
table th:first-child {
  border-radius: 5px 0 0 0;
}

table th:last-child {
  border-radius: 0 5px 0 0;
}
tbody {
  border-radius: 0 0 5px 5px;
}
.btnShow {
  display: none;
}

@media screen and (max-width: 600px) {
  .AffsubmitBtn {
    margin-left: 0;
  }
  .btnHide {
    display: none;
  }
  .btnShow {
    display: block;
    padding: 0 0.75rem;
  }
  .btnShow a {
    margin-top: 0;
  }
  .tableBox {
    padding: 10px;
    padding-bottom: 20px;
  }
}
</style>
